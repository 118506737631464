import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import './scss/style.scss';
import './scss/fonts/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import {ApplicationPaths} from './authorization/ApiAuthorizationConstants';
import ApiAuthorizationRoutes from './authorization/ApiAuthorizationRoutes';
import {Spinner} from './components';
import {handleLazyLoadError} from './helpers/commonHelper';
import {error} from 'jquery';

// Containers
const TheLayout = React.lazy(() =>
  import('./containers/TheLayout').catch((error) => handleLazyLoadError(error))
);
const ThePublicLayout = React.lazy(() =>
  import('./containers/ThePublicLayout').catch((error) => handleLazyLoadError(error))
);

// Pages
const Referral = React.lazy(() =>
  import('./pages/referral/ReferralPage').catch((error) => handleLazyLoadError(error))
);
const DeepLink = React.lazy(() =>
  import('./pages/deeplink/DeepLink').catch((error) => handleLazyLoadError(error))
);
const Page404 = React.lazy(() =>
  import('./views/pages/page404/Page404').catch((error) => handleLazyLoadError(error))
);
const Page500 = React.lazy(() =>
  import('./views/pages/page500/Page500').catch((error) => handleLazyLoadError(error))
);
const Redirect = React.lazy(() =>
  import('./pages/redirect/Redirect').catch((error) => handleLazyLoadError(error))
);

class App extends Component {
  render() {
    return (
      <React.Suspense fallback={<Spinner />}>
        <Switch>
          <Route
            path={ApplicationPaths.ApiAuthorizationPrefix}
            component={ApiAuthorizationRoutes}
          />

          <Route
            exact
            path="/referral/:referralId"
            name="Referral Page"
            render={() => <ThePublicLayout component={Referral} />}
          />

          <Route
            exact
            path="/deep-link/:id/:title"
            name="Deep link"
            render={() => <ThePublicLayout component={DeepLink} />}
          />

          <Route
            exact
            path="/deep-link/:id/:missionId/:title"
            name="Deep link"
            render={() => <ThePublicLayout component={DeepLink} />}
          />

          <Route
            exact
            path="/redirect"
            name="Redirect"
            render={() => <ThePublicLayout component={Redirect} />}
          />

          <Route
            exact
            path="/404"
            name="Page 404"
            render={() => <ThePublicLayout component={Page404} />}
          />

          <Route
            exact
            path="/500"
            name="Page 500"
            render={() => <ThePublicLayout component={Page500} />}
          />

          <Route path="/" name="UserLogin" component={(props) => <TheLayout {...props} />} />
        </Switch>
      </React.Suspense>
    );
  }
}

export default App;
