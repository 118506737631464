import {AttachReferencesType} from 'src/domain/enums/attach-references.type';
import {ContactSourceType} from 'src/domain/enums/contact-source.type';
import {PartnerStatus} from 'src/domain/enums/partner.status';
import {AutomaticInteractionStatus} from 'src/domain/enums/automatic-interaction.status';

const API_ENDPOINTS_HOST = {
  REACT_APP_API_MASTER_DATA: 'REACT_APP_API_MASTER_DATA',
  REACT_APP_API_AUTH: 'REACT_APP_API_AUTH',
  REACT_APP_API_CMS: 'REACT_APP_API_CMS',
  REACT_APP_API_CDP: 'REACT_APP_API_CDP',
  REACT_APP_API_GAME: 'REACT_APP_API_GAME',
  REACT_APP_IDENTITY_ADDRESS: 'REACT_APP_IDENTITY_ADDRESS',
  REACT_APP_API_CRM: 'REACT_API_API_CRM',
  REACT_APP_API_ADS: 'REACT_APP_API_ADS',
  REACT_APP_API_GIFT: 'REACT_APP_API_GIFT',
  REACT_APP_API_ACTIVITY: 'REACT_APP_API_ACTIVITY',
  REACT_APP_API_REPORT: 'REACT_APP_API_REPORT',
};

const API_METHOD = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

const STORAGE_KEY = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  MERCHANT_ID: 'MERCHANT_ID',
};

const DPOINT_PERMISSIONS = {
  UserLoginDpointAppDashboardMenuRead: 'UserLoginDpointAppDashboardMenuRead',
  UserLoginDpointAppDashboardRead: 'UserLoginDpointAppDashboardRead',
  UserReportDashboardMenuRead: 'UserReportDashboardMenuRead',
  UserReportDashboardRead: 'UserReportDashboardRead',
  MemberRankDashboardMenuRead: 'MemberRankDashboardMenuRead',
  MemberRankDashboardRead: 'MemberRankDashboardRead',
  BusinessDeploymentReportGeneralMenuRead: 'BusinessDeploymentReportGeneralMenuRead',
  BusinessDeploymentReportGeneralRead: 'BusinessDeploymentReportGeneralRead',
  BusinessDeploymentReportMenuRead: 'BusinessDeploymentReportMenuRead',
  BusinessDeploymentReportRead: 'BusinessDeploymentReportRead',
  BusinessDeploymentReportExport: 'BusinessDeploymentReportExport',
  BusinessDeploymentReportDailyMenuRead: 'BusinessDeploymentReportDailyMenuRead',
  BusinessDeploymentReportDailyRead: 'BusinessDeploymentReportDailyRead',
  ConvertUserReportMenuRead: 'ConvertUserReportMenuRead',
  ConvertUserReportRead: 'ConvertUserReportRead',
  ConvertUserReportExport: 'ConvertUserReportExport',
  ContentApproveMenuRead: 'ContentApproveMenuRead',
  ContentRead: 'ContentRead',
  ContentCreate: 'ContentCreate',
  ContentUpdate: 'ContentUpdate',
  ContentDelete: 'ContentDelete',
  ContentReview: 'ContentReview',
  ContentSettingMenuRead: 'ContentSettingMenuRead',
  ContentSettingRead: 'ContentSettingRead',
  ContentSettingCreate: 'ContentSettingCreate',
  ContentSettingUpdate: 'ContentSettingUpdate',
  ContentSettingDelete: 'ContentSettingDelete',
  ContentSettingReview: 'ContentSettingReview',
  GiftApproveMenuRead: 'GiftApproveMenuRead',
  GiftRead: 'GiftRead',
  GiftCreate: 'GiftCreate',
  GiftUpdate: 'GiftUpdate',
  GiftDelete: 'GiftDelete',
  GiftReview: 'GiftReview',
  GiftCategoryMenuRead: 'GiftCategoryMenuRead',
  GiftCategoryRead: 'GiftCategoryRead',
  GiftCategoryCreate: 'GiftCategoryCreate',
  GiftCategoryUpdate: 'GiftCategoryUpdate',
  GiftCategoryDelete: 'GiftCategoryDelete',
  GiftCategoryReview: 'GiftCategoryReview',
  GiftReportMenuRead: 'GiftReportMenuRead',
  GiftReportRead: 'GiftReportRead',
  GiftReportSendEmail: 'GiftReportSendEmail',
  GameMenuRead: 'GameMenuRead',
  GameRead: 'GameRead',
  GameCreate: 'GameCreate',
  GameUpdate: 'GameUpdate',
  GameDelete: 'GameDelete',
  GameReview: 'GameReview',
  GameSettingMenuRead: 'GameSettingMenuRead',
  GameSettingRead: 'GameSettingRead',
  GameSettingCreate: 'GameSettingCreate',
  GameSettingUpdate: 'GameSettingUpdate',
  GameSettingDelete: 'GameSettingDelete',
  GameSettingReview: 'GameSettingReview',
  GameCategoryMenuRead: 'GameCategoryMenuRead',
  GameCategoryRead: 'GameCategoryRead',
  GameCategoryCreate: 'GameCategoryCreate',
  GameCategoryUpdate: 'GameCategoryUpdate',
  GameCategoryDelete: 'GameCategoryDelete',
  GameCategoryReview: 'GameCategoryReview',
  TransactionPointMenuRead: 'TransactionPointMenuRead',
  TransactionPointRead: 'TransactionPointRead',
  TransactionGiftMenuRead: 'TransactionGiftMenuRead',
  TransactionGiftRead: 'TransactionGiftRead',
  RetailTransactionMenuRead: 'RetailTransactionMenuRead',
  RetailTransactionRead: 'RetailTransactionRead',
  RetailTransactionExport: 'RetailTransactionExport',
  MerchantMenuRead: 'MerchantMenuRead',
  MerchantRead: 'MerchantRead',
  MerchantCreate: 'MerchantCreate',
  MerchantUpdate: 'MerchantUpdate',
  MerchantDelete: 'MerchantDelete',
  MerchantReview: 'MerchantReview',
  StoreMenuRead: 'StoreMenuRead',
  StoreRead: 'StoreRead',
  StoreCreate: 'StoreCreate',
  StoreUpdate: 'StoreUpdate',
  StoreDelete: 'StoreDelete',
  StoreReview: 'StoreReview',
  AccessKeyMenuRead: 'AccessKeyMenuRead',
  AccessKeyRead: 'AccessKeyRead',
  AccessKeyCreate: 'AccessKeyCreate',
  AccessKeyUpdate: 'AccessKeyUpdate',
  AccessKeyDelete: 'AccessKeyDelete',
  AccessKeyExport: 'AccessKeyExport',
  LoyaltyProgramMenuRead: 'LoyaltyProgramMenuRead',
  LoyaltyProgramRead: 'LoyaltyProgramRead',
  LoyaltyProgramCreate: 'LoyaltyProgramCreate',
  LoyaltyProgramUpdate: 'LoyaltyProgramUpdate',
  LoyaltyProgramDelete: 'LoyaltyProgramDelete',
  LoyaltyProgramReview: 'LoyaltyProgramReview',
  LoyaltyObjectSettingMenuRead: 'LoyaltyObjectSettingMenuRead',
  LoyaltyObjectSettingRead: 'LoyaltyObjectSettingRead',
  LoyaltyObjectSettingCreate: 'LoyaltyObjectSettingCreate',
  LoyaltyObjectSettingUpdate: 'LoyaltyObjectSettingUpdate',
  LoyaltyObjectSettingDelete: 'LoyaltyObjectSettingDelete',
  LoyaltyObjectSettingReview: 'LoyaltyObjectSettingReview',
  FeedbackMenuRead: 'FeedbackMenuRead',
  FeedbackRead: 'FeedbackRead',
  ZoneMenuRead: 'ZoneMenuRead',
  ZoneRead: 'ZoneRead',
  ZoneCreate: 'ZoneCreate',
  ZoneUpdate: 'ZoneUpdate',
  ZoneDelete: 'ZoneDelete',
  ZoneReview: 'ZoneReview',
  ObjectSettingMenuRead: 'ObjectSettingMenuRead',
  ObjectSettingRead: 'ObjectSettingRead',
  ObjectSettingCreate: 'ObjectSettingCreate',
  ObjectSettingUpdate: 'ObjectSettingUpdate',
  ObjectSettingDelete: 'ObjectSettingDelete',
  ObjectSettingReview: 'ObjectSettingReview',
  FAQMenuRead: 'FAQMenuRead',
  FAQRead: 'FAQRead',
  FAQCreate: 'FAQCreate',
  FAQUpdate: 'FAQUpdate',
  FAQDelete: 'FAQDelete',
  FAQReview: 'FAQReview',
  FAQCategoryMenuRead: 'FAQCategoryMenuRead',
  FAQCategoryRead: 'FAQCategoryRead',
  FAQCategoryCreate: 'FAQCategoryCreate',
  FAQCategoryUpdate: 'FAQCategoryUpdate',
  FAQCategoryDelete: 'FAQCategoryDelete',
  FAQCategoryReview: 'FAQCategoryReview',
  UserGuideMenuRead: 'UserGuideMenuRead',
  UserGuideRead: 'UserGuideRead',
  UserGuideCreate: 'UserGuideCreate',
  UserGuideUpdate: 'UserGuideUpdate',
  ReviewManagementMenuRead: 'ReviewManagementMenuRead',
  ReviewManagementRead: 'ReviewManagementRead',
  ReviewManagementCreate: 'ReviewManagementCreate',
  ReviewManagementUpdate: 'ReviewManagementUpdate',
  ReviewManagementExport: 'ReviewManagementExport',
  DeepLinksManagementMenuRead: 'DeepLinksManagementMenuRead',
  DeepLinksManagementRead: 'DeepLinksManagementRead',
  DeepLinksManagementCreate: 'DeepLinksManagementCreate',
  DeepLinksManagementUpdate: 'DeepLinksManagementUpdate',
  DeepLinksManagementDelete: 'DeepLinksManagementDelete',
  DeepLinksManagementDownload: 'DeepLinksManagementDownload',
  ExchangeRewardMenuRead: 'ExchangeRewardMenuRead',
  ExchangeRewardRead: 'ExchangeRewardRead',
  ExchangeRewardCreate: 'ExchangeRewardCreate',
  ExchangeRewardUpdate: 'ExchangeRewardUpdate',
  ExchangeRewardDelete: 'ExchangeRewardDelete',
  ExchangeRewardReview: 'ExchangeRewardReview',
  RewardMenuRead: 'RewardMenuRead',
  RewardRead: 'RewardRead',
  RewardCreate: 'RewardCreate',
  RewardUpdate: 'RewardUpdate',
  RewardDelete: 'RewardDelete',
  RewardReview: 'RewardReview',
  DPointPermissionMenuRead: 'DPointPermissionMenuRead',
  DPointPermissionRead: 'DPointPermissionRead',
  DPointPermissionCreate: 'DPointPermissionCreate',
  DPointPermissionUpdate: 'DPointPermissionUpdate',
  DPointPermissionDelete: 'DPointPermissionDelete',
  MerchantPermissionMenuRead: 'MerchantPermissionMenuRead',
  MerchantPermissionRead: 'MerchantPermissionRead',
  MerchantPermissionCreate: 'MerchantPermissionCreate',
  MerchantPermissionUpdate: 'MerchantPermissionUpdate',
  MerchantPermissionDelete: 'MerchantPermissionDelete',
  MerchantPermissionTemplateMenuRead: 'MerchantPermissionTemplateMenuRead',
  MerchantPermissionTemplateRead: 'MerchantPermissionTemplateRead',
  MerchantPermissionTemplateCreate: 'MerchantPermissionTemplateCreate',
  MerchantPermissionTemplateUpdate: 'MerchantPermissionTemplateUpdate',
  MerchantPermissionTemplateDelete: 'MerchantPermissionTemplateDelete',
  MerchantAdminMenuRead: 'MerchantAdminMenuRead',
  MerchantAdminRead: 'MerchantAdminRead',
  MerchantAdminCreate: 'MerchantAdminCreate',
  MerchantAdminUpdate: 'MerchantAdminUpdate',
  MerchantAdminDelete: 'MerchantAdminDelete',
  DPointAdminMenuRead: 'DPointAdminMenuRead',
  DPointAdminRead: 'DPointAdminRead',
  DPointAdminCreate: 'DPointAdminCreate',
  DPointAdminUpdate: 'DPointAdminUpdate',
  DPointAdminDelete: 'DPointAdminDelete',
  DPointAdminAccessGroupRead: 'DPointAdminAccessGroupRead',
  DPointAdminPermissionAccountRead: 'DPointAdminPermissionAccountRead',
  DPointUserMenuRead: 'DPointUserMenuRead',
  DPointUserRead: 'DPointUserRead',
  DPointUserUpdate: 'DPointUserUpdate',
  DPointUserExport: 'DPointUserExport',
  MerchantAdminRegisterMenuRead: 'MerchantAdminRegisterMenuRead',
  MerchantAdminRegisterRead: 'MerchantAdminRegisterRead',
  MerchantAdminRegisterCreate: 'MerchantAdminRegisterCreate',
  MerchantAdminRegisterUpdate: 'MerchantAdminRegisterUpdate',
  MerchantAdminRegisterReview: 'MerchantAdminRegisterReview',
  MerchantRegistrationNotificationMenuRead: 'MerchantRegistrationNotificationMenuRead',
  MerchantRegistrationNotificationRead: 'MerchantRegistrationNotificationRead',
  MerchantRegistrationNotificationUpdate: 'MerchantRegistrationNotificationUpdate',
  CheckinMenuRead: 'CheckinMenuRead',
  CheckinRead: 'CheckinRead',
  CheckinCreate: 'CheckinCreate',
  CheckinUpdate: 'CheckinUpdate',
  CheckinDelete: 'CheckinDelete',
  DashboardCheckinMenuRead: 'DashboardCheckinMenuRead',
  DashboardCheckinRead: 'DashboardCheckinRead',
  DashboardCheckinUpdate: 'DashboardCheckinUpdate',
  VersionControlMenuRead: 'VersionControlMenuRead',
  VersionControlRead: 'VersionControlRead',
  VersionControlCreate: 'VersionControlCreate',
  VersionControlUpdate: 'VersionControlUpdate',
  VersionControlDelete: 'VersionControlDelete',
  NotificationControlMenuRead: 'NotificationControlMenuRead',
  NotificationControlDetailRead: 'NotificationControlDetailRead',
  NotificationControlDetailReview: 'NotificationControlDetailReview',
  NotificationControlDetailUpdate: 'NotificationControlDetailUpdate',
  NotificationControlDetailDelete: 'NotificationControlDetailDelete',
  NotificationMenuRead: 'NotificationMenuRead',
  NotificationRead: 'NotificationRead',
  NotificationCreate: 'NotificationCreate',
  NotificationUpdate: 'NotificationUpdate',
  NotificationDelete: 'NotificationDelete',
  NotificationReportMenuRead: 'NotificationReportMenuRead',
  NotificationReportRead: 'NotificationReportRead',
  NotificationTemplateMenuRead: 'NotificationTemplateMenuRead',
  NotificationTemplateRead: 'NotificationTemplateRead',
  NotificationTemplateDelete: 'NotificationTemplateDelete',
  NotificationTemplateCreate: 'NotificationTemplateCreate',
  NotificationTemplateUpdate: 'NotificationTemplateUpdate',
  MessageRateMenuRead: 'MessageRateMenuRead',
  MessageRateRead: 'MessageRateRead',
  MessageRateCreate: 'MessageRateCreate',
  MessageRateUpdate: 'MessageRateUpdate',
  TicketSettingsMenuRead: 'TicketSettingsMenuRead',
  TicketSettingsRead: 'TicketSettingsRead',
  TicketSettingsCreate: 'TicketSettingsCreate',
  TicketSettingsUpdate: 'TicketSettingsUpdate',
  TicketSettingsDelete: 'TicketSettingsDelete',
  TicketListMenuRead: 'TicketListMenuRead',
  TicketListRead: 'TicketListRead',
  TicketListCreate: 'TicketListCreate',
  TicketListUpdate: 'TicketListUpdate',
  TicketListExport: 'TicketListExport',
  ReportPayableDebtMenuRead: 'ReportPayableDebtMenuRead',
  ReportPayableDebtRead: 'ReportPayableDebtRead',
  ReportPayableDebtExport: 'ReportPayableDebtExport',
  MerchantReportMenuDPointPortalRead: 'MerchantReportMenuDPointPortalRead',
  MerchantReportRead: 'MerchantReportRead',
  MerchantReportExport: 'MerchantReportExport',
  ReturnRateReportMenuRead: 'ReturnRateReportMenuRead',
  ReturnRateReportRead: 'ReturnRateReportRead',
  ReturnRateReportExport: 'ReturnRateReportExport',
  BannedKeywordMenuRead: 'BannedKeywordMenuRead',
  BannedKeywordRead: 'BannedKeywordRead',
  BannedKeywordUpdate: 'BannedKeywordUpdate',
  SearchKeywordMenuRead: 'SearchKeywordMenuRead',
  SearchKeywordRead: 'SearchKeywordRead',
  SearchKeywordExport: 'SearchKeywordExport',
  SuggestionRuleMenuRead: 'SuggestionRuleMenuRead',
  SuggestionRuleRead: 'SuggestionRuleRead',
  SuggestionRuleUpdate: 'SuggestionRuleUpdate',
  ObjectSettingsCDPMenuRead: 'ObjectSettingsCDPMenuRead',
  ObjectSettingsCDPRead: 'ObjectSettingsCDPRead',
  CustomerSegmentationMenuRead: 'CustomerSegmentationMenuRead',
  CustomerSegmentationRead: 'CustomerSegmentationRead',
  InchargeContent: 'InchargeContent',
  InchargeGift: 'InchargeGift',
  InchargeContentSettings: 'InchargeContentSettings',
  InchargeNotification: 'InchargeNotification',
  HandleTicket: 'HandleTicket',
  InchargeExchangePoint: 'InchargeExchangePoint',
  InchargeLoyalty: 'InchargeLoyalty',
  InchargeGameSettings: 'InchargeGameSettings',
  InchargeReward: 'InchargeReward',
  MerchantDeploymentTrackingRead: 'MerchantDeploymentTrackingRead',
  MerchantDeploymentTrackingDeploy: 'MerchantDeploymentTrackingDeploy',
  MerchantPosRead: 'MerchantPosRead',
  MerchantPosCreate: 'MerchantPosCreate',
  MerchantPosUpdate: 'MerchantPosUpdate',
  NotificationVariableMenuRead: 'NotificationVariableMenuRead',
  NotificationVariableRead: 'NotificationVariableRead',
  NotificationVariableCreate: 'NotificationVariableCreate',
  NotificationVariableUpdate: 'NotificationVariableUpdate',
  DefaultPasswordManagementRead: 'DefaultPasswordManagementRead',
  DefaultPasswordManagementCreate: 'DefaultPasswordManagementCreate',
  DefaultPasswordManagementUpdate: 'DefaultPasswordManagementUpdate',
  ExternalMerchantMenuRead: 'ExternalMerchantMenuRead',
  ExternalMerchantRead: 'ExternalMerchantRead',
  ExternalMerchantCreate: 'ExternalMerchantCreate',
  ExternalMerchantUpdate: 'ExternalMerchantUpdate',
  InteractionTemplateMenuRead: 'InteractionTemplateMenuRead',
  InteractionTemplateRead: 'InteractionTemplateRead',
  InteractionTemplateDelete: 'InteractionTemplateDelete',
  InteractionTemplateCreate: 'InteractionTemplateCreate',
  InteractionTemplateUpdate: 'InteractionTemplateUpdate',
  GiftDistributionPartnerMenuRead: 'GiftDistributionPartnerMenuRead',
  GiftDistributionPartnerRead: 'GiftDistributionPartnerRead',
  GiftDistributionPartnerUpdate: 'GiftDistributionPartnerUpdate',
  GiftDistributionPartnerCreate: 'GiftDistributionPartnerCreate',
  GiftDistributionPartnerDelete: 'GiftDistributionPartnerDelete',
  GiftCodeManagerMenuRead: 'GiftCodeManagerMenuRead',
  GiftCodeManagerRead: 'GiftCodeManagerRead',
  AutomaticInteractionMenuRead: 'AutomaticInteractionMenuRead',
  AutomaticInteractionRead: 'AutomaticInteractionRead',
  AutomaticInteractionDelete: 'AutomaticInteractionDelete',
  AutomaticInteractionCreate: 'AutomaticInteractionCreate',
  AutomaticInteractionUpdate: 'AutomaticInteractionUpdate',
  InteractionControlMenuRead: 'InteractionControlMenuRead',
  InteractionControlDetailRead: 'InteractionControlDetailRead',
  InteractionControlDetailReview: 'InteractionControlDetailReview',
  InteractionControlDetailUpdate: 'InteractionControlDetailUpdate',
  InteractionControlDetailDelete: 'InteractionControlDetailDelete',
};

const EVENTTIMEOPTION = [
  {
    id: 'EVENT_DATE',
    label: 'Ngày diễn ra sự kiện',
  },
  {
    id: 'EVENT_WEEK',
    label: 'Tuần diễn ra sự kiện',
  },
  {
    id: 'EVENT_MONTH',
    label: 'Tháng diễn ra sự kiện',
  },
];
const ACTIONOPTIONS = [
  // {
  //   id: "VISIT_STORE",
  //   label: "Ghé cửa hàng",
  // },
  {
    id: 'ACT_PURCHASE',
    label: 'Mua hàng',
  },
  // {
  //   id: "VISIT_WEBSITE",
  //   label: "Ghé website",
  // },
  {
    id: 'ACT_REFERRAL_LOYALTY',
    label: 'Mời bạn đăng ký LP',
  },
  {
    id: 'ACT_REFERRAL',
    label: 'Giới thiệu bạn (Tải Dpoint)',
  },
  {
    id: 'ACT_DO_MISSION',
    label: 'Làm nhiệm vụ',
  },
  {
    id: 'ACT_UPDATE_PROFILE',
    label: 'Cập nhật thông tin cá nhân',
  },
  {
    id: 'ACT_REGISTER_MEMBERSHIP',
    label: 'Đăng ký LP',
  },
  {
    id: 'ACT_GENERAL',
    label: 'Trống',
  },
  {
    id: 'ACT_REFERRAL_LOYALTY_AND_PURCHASE',
    label: 'Mời bạn đăng ký LP và phát sinh đơn hàng',
  },
];

const STORE_TYPE = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
};

const GAME_TYPE = {
  LUCKY_WHEEL: 'LUCKY_DRAW',
  LOTTERY: 'LOTTERY',
  SLIDING_BLOCK: 'SLIDINGBLOCK',
  FLAPPY_BIRD: 'FLAPPYBIRD',
};

const DYNAMIC_TYPE = {
  MERCHANT_SHOP: 1,
  LOYALTY_PROGRAM: 2,
};

const KEYCODES = {
  comma: 188,
  enter: 13,
};

const STATUS = {
  INACTIVE: 0,
  ACTIVE: 1,
};

// REVIEW MANAGEMENT
const DISPLAYSCREEN = [
  {
    value: 'USE_GIFT_SUCCESSFUL',
    label: 'Màn hình sử dụng quà thành công',
  },
  {
    value: 'REDEEM_GIFT_SUCCESSFUL',
    label: 'Màn hình đổi quà thành công',
  },
  {
    value: 'PAGE_RESULT_GIFTS_FOR_YOU',
    label: 'Search ở Danh sách quà tặng/ Dành cho bạn - Page result',
  },
  {
    value: 'PAGE_RESULT_NEWS',
    label: 'Search tin tức - Page result',
  },
  {
    value: 'MISSION_OR_SURVEY_COMPLETED',
    label: 'Tin tức - Thực hiện xong nhiệm vụ/Khảo sát',
  },
  {
    value: 'NO_MISSION',
    label: 'Tin tức - Không có nhiệm vụ',
  },
  {
    value: 'PAGE_RESULT_HOME',
    label: 'Search ở màn hình Home - Page result',
  },
  {
    value: 'GAME_OVER',
    label: 'Trò chơi - khi chơi xong',
  },
];

const CLASSIFIEDGIFTLIST = [
  {label: 'Quà để Chill', value: 'CHILL_GIFT'},
  {label: 'E-voucher', value: 'E_VOUCHER'},
  {label: 'Quà cửa hàng', value: 'STORE_GIFT'},
];

const NOTIFICATION_CLASSIFY = {
  MERCHANT_TO_DPOINT: 'MERCHANT_TO_DPOINT',
  INTERNAL_DPOINT: 'INTERNAL_DPOINT',
  DPOINT_USER_PORTAL: 'DPOINT_USER_PORTAL',
  MERCHANT_ACCOUNT_REGISTER: 'MERCHANT_ACCOUNT_REGISTER',
};

const PROCESSING_STATUS = [
  {
    id: 'GIFT_PENDING',
    label: 'Chờ duyệt',
    color: 'bg-warning',
  },
  {
    id: 'GIFT_APPROVED',
    label: 'Đã duyệt',
    color: 'bg-success',
  },
  {
    id: 'GIFT_REJECTED',
    label: 'Đã từ chối',
    color: 'bg-danger',
  },
  {
    id: 'GIFT_PENDING_REMOVED_ON_APP',
    label: 'Chờ gỡ khỏi ứng dụng',
    color: 'bg-warning',
  },
  {
    id: 'GIFT_REMOVED_ON_APP',
    label: 'Đã gỡ khỏi ứng dụng',
    color: 'bg-secondary',
  },
  {
    id: 'LOYALTY_PENDING',
    label: 'Chờ duyệt',
    color: 'bg-warning',
  },
  {
    id: 'LOYALTY_APPROVED',
    label: 'Đã duyệt',
    color: 'bg-success',
  },
  {
    id: 'LOYALTY_REJECTED',
    label: 'Đã từ chối',
    color: 'bg-danger',
  },
  {
    id: 'REWARD_PENDING',
    label: 'Chờ duyệt',
    color: 'bg-warning',
  },
  {
    id: 'REWARD_APPROVED',
    label: 'Đã duyệt',
    color: 'bg-success',
  },
  {
    id: 'REWARD_REJECTED',
    label: 'Đã từ chối',
    color: 'bg-danger',
  },
  {
    id: 'EXCHANGE_REWARD_PENDING',
    label: 'Chờ duyệt',
    color: 'bg-warning',
  },
  {
    id: 'EXCHANGE_REWARD_APPROVED',
    label: 'Đã duyệt',
    color: 'bg-success',
  },
  {
    id: 'EXCHANGE_REWARD_REJECTED',
    label: 'Đã từ chối',
    color: 'bg-danger',
  },
  {
    id: 'NOTI_SETTING_PENDING',
    label: 'Chờ duyệt',
    color: 'bg-warning',
  },
  {
    id: 'NOTI_SETTING_APPROVED',
    label: 'Đã duyệt',
    color: 'bg-success',
  },
  {
    id: 'NOTI_SETTING_REJECTED',
    label: 'Đã từ chối',
    color: 'bg-danger',
  },
  {
    id: 'CONTENT_PENDING',
    label: 'Chờ duyệt',
    color: 'bg-warning',
  },
  {
    id: 'CONTENT_APPROVED',
    label: 'Đã duyệt',
    color: 'bg-success',
  },
  {
    id: 'CONTENT_REJECTED',
    label: 'Đã từ chối',
    color: 'bg-danger',
  },
  {
    id: 'CONTENT_REMOVED_ON_APP',
    label: 'Đã gỡ khỏi ứng dụng',
    color: 'bg-secondary',
  },
  {
    id: 'CONTENT_PENDING_REMOVE_ON_APP',
    label: 'Chờ gỡ khỏi ứng dụng',
    color: 'bg-warning',
  },
  {
    id: 'CONTENT_SETTING_PENDING',
    label: 'Chờ duyệt',
    color: 'bg-warning',
  },
  {
    id: 'CONTENT_SETTING_APPROVED',
    label: 'Đã duyệt',
    color: 'bg-success',
  },
  {
    id: 'CONTENT_SETTING_REJECTED',
    label: 'Đã từ chối',
    color: 'bg-danger',
  },
  {
    id: 'CONTENT_SETTING_REMOVED_ON_APP',
    label: 'Đã gỡ khỏi ứng dụng',
    color: 'bg-secondary',
  },
  {
    id: 'CONTENT_SETTING_PENDING_REMOVE_ON_APP',
    label: 'Chờ gỡ khỏi ứng dụng',
    color: 'bg-warning',
  },
  {
    id: 'GAME_SETTING_PENDING',
    label: 'Chờ duyệt',
    color: 'bg-warning',
  },
  {
    id: 'GAME_SETTING_APPROVED',
    label: 'Đã duyệt',
    color: 'bg-success',
  },
  {
    id: 'GAME_SETTING_REJECTED',
    label: 'Đã từ chối',
    color: 'bg-danger',
  },
  {
    id: 'GAME_SETTING_REMOVED_ON_APP',
    label: 'Đã gỡ khỏi ứng dụng',
    color: 'bg-secondary',
  },
  {
    id: 'GAME_SETTING_PENDING_REMOVE_ON_APP',
    label: 'Chờ gỡ khỏi ứng dụng',
    color: 'bg-warning',
  },
  {
    id: 'MERCHANT_ACCOUNT_REQUESTED',
    label: 'Chờ duyệt',
    color: 'bg-warning',
  },
  {
    id: 'MERCHANT_ACCOUNT_ACCEPTED',
    label: 'Đã duyệt',
    color: 'bg-success',
  },
  {
    id: 'NOTI_GIFT_PENDING',
    label: 'Chờ duyệt',
    color: 'bg-warning',
  },
  {
    id: 'NOTI_GIFT_APPROVED',
    label: 'Đã duyệt',
    color: 'bg-success',
  },
  {
    id: 'NOTI_GIFT_REJECTED',
    label: 'Từ chối',
    color: 'bg-danger',
  },
];

const NOTIFICATION_TYPE_ENDPOINT = [
  {
    id: 'GIFT',
    value: '/gift/',
  },
  {
    id: 'LOYALTY',
    value: '/loyalty/',
  },
  {
    id: 'REWARD',
    value: '/reward/',
  },
  {
    id: 'EXCHANGE_REWARD',
    value: '/exchange-fee/',
  },
  {
    id: 'GAME_SETTING',
    value: '/game-request/',
  },
  {
    id: 'TICKET',
    value: '/list-tickets/',
  },
  {
    id: 'CONTENT',
    value: '/content/',
  },
  {
    id: 'NOTI_SETTING',
    value: '/notification-control/',
  },
  {
    id: 'CONTENT_SETTING',
    value: '/content-setting/',
  },
  {
    id: 'NOTI_GIFT',
    value: '/gift-notification/',
  },
];

const ATTACH_REFERENCES_TYPE_DATA = [
  {label: 'Quà tặng', value: AttachReferencesType.GIFT},
  {label: 'Trò chơi', value: AttachReferencesType.GAME},
  {label: 'Nhiệm vụ', value: AttachReferencesType.MISSION},
  {label: 'Nội dung', value: AttachReferencesType.CONTENT},
];

const REDIRECT_PATHS = {
  GIFT: 'gift',
  LOYALTY: 'loyalty',
  REWARD: 'reward',
  EXCHANGE_REWARD: 'exchange-fee',
  GAME_SETTING: 'game-setting',
  CONTENT: 'content',
  CONTENT_SETTING: 'content-setting',
  TICKET: 'list-tickets',
  NOTI_SETTING: 'notification-control',
  MERCHANT_ACCOUNT_REGISTER: 'merchant-admin-register',
  STORE: 'store',
};

const DEEPLINK_TABNAME_DATA = {
  MERCHANT_SCREEN: 'MERCHANT_SCREEN',
  DETAIL_SCREEN: 'DETAIL_SCREEN',
  FUNCTION_SCREEN: 'FUNCTION_SCREEN',
  HOME_PAGE: 'HOME_PAGE',
};

const DEEPLINK_TABID_OPTIONS = [
  {
    label: 'Merchant Page - Home',
    value: 'HOME',
  },
  {
    label: 'Tab ưu đãi',
    value: 'GIFT',
  },
  {
    label: 'Tab tin tức',
    value: 'CONTENT',
  },
  {
    label: 'Tab chơi trò chơi',
    value: 'GAME',
  },
  {
    label: 'Tab cửa hàng',
    value: 'STORE',
  },
  {
    label: 'Tab thông tin chương trình',
    value: 'LOYALTY_PROGRAM',
  },
  {
    label: 'Tab chính sách đổi điểm',
    value: 'EXCHANGE_POINT',
  },
  {
    label: 'Tab hạng thành viên',
    value: 'MEMBERSHIP_RANKING',
  },
  {
    label: 'Thẻ thành viên của 1 Merchant',
    value: 'MERCHANT_REGISTER',
  },
  {
    label: 'Thẻ TV Mer/ Giới thiệu bạn tích điểm',
    value: 'REFERRAL',
  },
  {
    label: 'Thẻ TV Mer/ Liên hệ',
    value: 'CONTACT',
  },
];

const DEEPLINK_DETAIL_TYPE_OPTIONS = [
  {
    label: 'Nội dung',
    value: 'CONTENT',
  },
  {
    label: 'Quà tặng',
    value: 'GIFT',
  },
  {
    label: 'Trò chơi',
    value: 'GAME',
  },
  // {
  //   label: 'Khảo sát/Câu hỏi',
  //   value: 'MISSION',
  // },
];

const DEEPLINK_GAMETYPE_OPTIONS = [
  {
    label: 'Trò chơi Vòng quay may mắn',
    value: 'LUCKY_DRAW',
  },
  {
    label: 'Trò chơi Xổ số trúng thưởng',
    value: 'LOTTERY',
  },
  {
    label: 'Trò chơi 2048',
    value: 'SLIDINGBLOCK',
  },
  {
    label: 'Trò chơi Flappy bird',
    value: 'FLAPPYBIRD',
  },
];

const AUDIT_LOG_TYPE = {
  GIFT: 'GIFT',
  CONTENT: 'CONTENT',
  CONTENT_SETTING: 'CONTENT_SETTING',
  NOTI_SETTING: 'NOTIFICATION_SETTING',
  TICKET: 'TICKET',
  EXCHANGE_REWARD: 'EXCHANGE_REWARD',
  LOYALTY: 'LOYALTY_PROGRAM',
  GAME_SETTING: 'GAME_SETTING',
  REWARD: 'PROGRAM_REWARD',
};

const PERMISSION_TABNAME_DATA = {
  ACCESS_CONTROL: 'ACCESS_CONTROL',
  ACCESS_CONTROL_AND_ACCOUNT: 'ACCESS_CONTROL_AND_ACCOUNT',
};

const NULL_OBJECT_ID = '000000000000000000000000';

const MERCHANT_ACCOUNT_CONTACT_SOURCE = [
  {
    label: 'Merchant App',
    value: ContactSourceType.MERCHANT_APP,
  },
  {
    label: 'Web DPoint',
    value: ContactSourceType.WEB_DPOINT,
  },
];

const MERCHANT_ACCOUNT_ROLE_NAME = [
  {
    label: 'Chủ quán',
    value: 'OWNER',
  },
  {
    label: 'Quản lý',
    value: 'MANAGER',
  },
  {
    label: 'Nhân viên',
    value: 'STAFF',
  },
  {
    label: 'Người liên hệ',
    value: 'CONTACTPERSON',
  },
];

const GIFT_TIME_OPTION_VALUE = {
  START_REDEEM_BY_SPECIFIC_DATETIME: 0,
  END_REDEEM_BY_SPECIFIC_DATETIME: 0,

  START_USAGE_BY_SPECIFIC_DATETIME: 0,
  START_USAGE_BY_SPECIFIC_EVENT: 1,
  START_USAGE_EVENT_BY_RECEIVED_DAY: 0,

  END_USAGE_BY_SPECIFIC_DATETIME: 0,
  END_USAGE_BY_DURATION: 1,
  END_USAGE_BY_TIMELINE: 2,
  END_USAGE_DURATION_BY_DATE: 'DATE',
  END_USAGE_DURATION_BY_WEEK: 'WEEK',
  END_USAGE_DURATION_BY_MONTH: 'MONTH',
  END_USAGE_DURATION_BY_YEAR: 'YEAR',
};

const GIFT_TIME_START_REDEEM_OPTIONS = [
  {label: 'Thời gian cụ thể', value: GIFT_TIME_OPTION_VALUE.START_REDEEM_BY_SPECIFIC_DATETIME},
];

const GIFT_TIME_END_REDEEM_OPTIONS = [
  {label: 'Thời gian cụ thể', value: GIFT_TIME_OPTION_VALUE.END_REDEEM_BY_SPECIFIC_DATETIME},
];

const GIFT_TIME_START_USAGE_OPTIONS = [
  {label: 'Thời gian cụ thể', value: GIFT_TIME_OPTION_VALUE.START_USAGE_BY_SPECIFIC_DATETIME},
  {label: 'Sự kiện cụ thể', value: GIFT_TIME_OPTION_VALUE.START_USAGE_BY_SPECIFIC_EVENT},
];

const GIFT_TIME_START_USAGE_BY_SPECIFIC_EVENT_OPTIONS = [
  {label: 'Ngày nhận voucher', value: GIFT_TIME_OPTION_VALUE.START_USAGE_EVENT_BY_RECEIVED_DAY},
];

const GIFT_TIME_END_USAGE_OPTIONS = [
  {label: 'Thời gian cụ thể', value: GIFT_TIME_OPTION_VALUE.END_USAGE_BY_SPECIFIC_DATETIME},
  {label: 'Khoảng thời gian', value: GIFT_TIME_OPTION_VALUE.END_USAGE_BY_DURATION},
  {label: 'Mốc thời gian', value: GIFT_TIME_OPTION_VALUE.END_USAGE_BY_TIMELINE},
];

const GIFT_TIME_END_USAGE_BY_DURATION_OPTIONS = [
  {label: 'Ngày', value: GIFT_TIME_OPTION_VALUE.END_USAGE_DURATION_BY_DATE},
  {label: 'Tuần', value: GIFT_TIME_OPTION_VALUE.END_USAGE_DURATION_BY_WEEK},
  {label: 'Tháng', value: GIFT_TIME_OPTION_VALUE.END_USAGE_DURATION_BY_MONTH},
  {label: 'Năm', value: GIFT_TIME_OPTION_VALUE.END_USAGE_DURATION_BY_YEAR},
];

const GIFT_TIME_END_USAGE_BY_TIMELINE_OPTIONS = [
  {label: 'Cuối ngày nhận', value: GIFT_TIME_OPTION_VALUE.END_USAGE_DURATION_BY_DATE},
  {label: 'Cuối tuần nhận', value: GIFT_TIME_OPTION_VALUE.END_USAGE_DURATION_BY_WEEK},
  {label: 'Cuối tháng nhận', value: GIFT_TIME_OPTION_VALUE.END_USAGE_DURATION_BY_MONTH},
  {label: 'Cuối năm nhận', value: GIFT_TIME_OPTION_VALUE.END_USAGE_DURATION_BY_YEAR},
];

const MAX_PROMOTION_PER_USER_PERIOD_TYPE = [
  {label: 'Chương trình', value: 'PROGRAM'},
  {label: 'Năm', value: 'YEAR'},
  {label: 'Tháng', value: 'MONTH'},
  {label: 'Tuần', value: 'WEEK'},
  {label: 'Ngày', value: 'DAY'},
];

const EARNING_RULE_PERFORMANCE_TYPE = {
  ACCUMULATED_PURCHASE_AMOUNT: 'ACCUMULATED_PURCHASE_AMOUNT',
  ACCUMULATED_REWARDS: 'ACCUMULATED_REWARDS',
  BILL_AMOUNT: 'BILL_AMOUNT',
  REFERRAL_NUMBER: 'REFERRAL_NUMBER',
  VALUE_OF_PREVIOUSLY_PURCHASED_ORDERS: 'VALUE_OF_PREVIOUSLY_PURCHASED_ORDERS',
  GIFT_FOR_NEW_ACCOUNT: 'GIFT_FOR_NEW_ACCOUNT',
  ACCUMULATED_PURCHASE_NUMBER: 'ACCUMULATED_PURCHASE_NUMBER',
  QUALIFIED_RANK: 'QUALIFIED_RANK',
};

const MAX_EARNING_RULE_PER_USER_PERIOD_TYPE = [
  {label: 'Ngày', value: 'DAY'},
  {label: 'Tuần', value: 'WEEK'},
  {label: 'Tháng', value: 'MONTH'},
  {label: 'Năm', value: 'YEAR'},
  {label: 'Sự kiện', value: 'EVENT'},
];

const POS_NAME_OPTIONS = [
  {label: 'KAS POS', value: 'KAS POS'},
  {label: 'DCorp R-Keeper POS', value: 'DCorp R-Keeper POS'},
  {label: 'iPOS', value: 'iPOS'},
  {label: 'Haravan POS', value: 'Haravan POS'},
  {label: 'Sapo POS', value: 'Sapo POS'},
  {label: 'KiotViet POS', value: 'KiotViet POS'},
  {label: 'SpeedPOS', value: 'SpeedPOS'},
  {label: 'GoSell POS', value: 'GoSell POS'},
  {label: 'Khác', value: 'Others'},
];

const PASSWORD_MANAGEMENT_TABNAME = {
  USER_ACCOUNT: 'USER_ACCOUNT',
  MERCHANT_ACCOUNT: 'MERCHANT_ACCOUNT',
};

const PASSWORD_MANAGEMENT_TYPE_OPTIONS = [
  {
    label: 'Mật khẩu hệ thống',
    value: true,
  },
  {
    label: 'Mật khẩu cá nhân',
    value: false,
  },
];

const CONTENT_ATTACH_REFERENCES_TYPE = {
  GIFT: 1,
  GAME: 2,
};

const PARTNER_STATUS_OPTIONS = [
  {
    label: 'Hoạt động',
    value: PartnerStatus.ACTIVE,
  },
  {
    label: 'Ngưng hoạt động',
    value: PartnerStatus.INACTIVE,
  },
];

const NOTIFICATION_CHANNELS = {
  SMS: 'SMS',
  ZNS: 'ZNS',
  DPOINT_APP: 'DPOINT_APP',
};

const NOTIFICATION_CHANNEL_OPTIONS = [
  {
    label: 'SMS',
    value: NOTIFICATION_CHANNELS.SMS,
  },
  {
    label: 'ZNS',
    value: NOTIFICATION_CHANNELS.ZNS,
  },
  {
    label: 'App DPoint',
    value: NOTIFICATION_CHANNELS.DPOINT_APP,
  },
];

const CONTENT_TEMPLATE_CHANNEL_OPTIONS = [
  {
    label: 'App DPoint',
    value: NOTIFICATION_CHANNELS.DPOINT_APP,
  },
];

const ATTACHMENT_TYPE_OPTIONS = [
  {
    label: 'Quà tặng',
    value: 1,
  },
  {
    label: 'Trò chơi',
    value: 2,
  },
  {
    label: 'Nhiệm vụ',
    value: 3,
  },
  {label: 'Nội dung', value: 4},
];

const CONTENT_TEMPLATE_POSITION_CTA = {
  DPOINT_APP: 0,
  WEB: 1,
};

const POSITION_CTA_OPTIONS = [
  {
    label: 'Đến vị trí cụ thể trên app DPoint',
    value: CONTENT_TEMPLATE_POSITION_CTA.DPOINT_APP,
  },
  {
    label: 'Đến trang web',
    value: CONTENT_TEMPLATE_POSITION_CTA.WEB,
  },
];

const AUTOMATIC_INTERACTION_STATUS_OPTIONS = [
  {
    label: 'Bản nháp',
    value: AutomaticInteractionStatus.DRAFT,
  },
  {
    label: 'Chờ duyệt',
    value: AutomaticInteractionStatus.PENDING,
  },
  {
    label: 'Đang duyệt',
    value: AutomaticInteractionStatus.WAITING_FOR_APPROVAL,
  },
  {
    label: 'Đã duyệt',
    value: AutomaticInteractionStatus.APPROVED,
  },
  {
    label: 'Từ chối',
    value: AutomaticInteractionStatus.REJECTED,
  },
  // {
  //   label: 'Đã xoá',
  //   value: AutomaticInteractionStatus.DELETED,
  // },
];

const AUTOMATIC_INTERACTION_VALIDITY_PERIOD = {
  LIMITED: 0,
  FOREVER: 1,
};

const AUTOMATIC_INTERACTION_VALIDITY_PERIOD_OPTIONS = [
  {
    label: 'Có thời hạn',
    value: AUTOMATIC_INTERACTION_VALIDITY_PERIOD.LIMITED,
  },
  {
    label: 'Vĩnh viễn',
    value: AUTOMATIC_INTERACTION_VALIDITY_PERIOD.FOREVER,
  },
];

const AUTO_INTERACTION_MAX_PER_USER_PERIOD_TYPE = [
  {label: 'Ngày', value: 'DAY'},
  {label: 'Tháng', value: 'MONTH'},
  {label: 'Năm', value: 'YEAR'},
  {label: 'Toàn bộ thời gian hiệu lực', value: 'VALIDITY_PERIOD'},
];

const TIME_TYPE_1 = {
  MILLISECOND: 'Millisecond',
  SECOND: 'Second',
  MINUTE: 'Minute',
  HOUR: 'Hour',
  DAY: 'Day',
  MONTH: 'Month',
  YEAR: 'Year',
};

const AUTO_INTERACTION_ACTIVITY_PERIOD_TYPE = [
  {label: 'Giờ', value: TIME_TYPE_1.HOUR},
  {label: 'Ngày', value: TIME_TYPE_1.DAY},
  {label: 'Tháng', value: TIME_TYPE_1.MONTH},
  {label: 'Năm', value: TIME_TYPE_1.YEAR},
];

const ACTIVITY_TYPE = {
  ACTIVE: 'ACTIVE',
  REMIND: 'REMIND',
};

const AUTO_INTERACTION_SENDING_TIME_TYPE = {
  SEND_RIGHT_AFTER_EVENT: {label: 'Gửi ngay khi hoạt động diễn ra', value: 0},
  SEND_AFTER_EVENT: {label: 'Sau khi hoạt động diễn ra', value: 1},
  SEND_DAY_OF_WEEK: {label: 'Thứ cụ thể trong tuần', value: 2},
  SEND_DAY_OF_MONTH: {label: 'Ngày cụ thể trong tháng', value: 3},
  SEND_DAY_OF_YEAR: {label: 'Ngày cụ thể trong năm', value: 4},
};

const AUTO_INTERACTION_SENDING_TIME_PERIOD_TYPE = [
  {label: 'Phút', value: TIME_TYPE_1.MINUTE},
  {label: 'Giờ', value: TIME_TYPE_1.HOUR},
  {label: 'Ngày', value: TIME_TYPE_1.DAY},
  {label: 'Tháng', value: TIME_TYPE_1.MONTH},
  {label: 'Năm', value: TIME_TYPE_1.YEAR},
];

const DAY_OF_WEEK_OPTIONS = [
  {label: 'Thứ hai', value: 1, order: 1},
  {label: 'Thứ ba', value: 2, order: 2},
  {label: 'Thứ tư', value: 3, order: 3},
  {label: 'Thứ năm', value: 4, order: 4},
  {label: 'Thứ sáu', value: 5, order: 5},
  {label: 'Thứ bảy', value: 6, order: 6},
  {label: 'Chủ nhật', value: 0, order: 7},
];

const DAY_OF_MONTH_OPTIONS = [
  {
    label: 'Ngày 1',
    value: 1,
  },
  {
    label: 'Ngày 2',
    value: 2,
  },
  {
    label: 'Ngày 3',
    value: 3,
  },
  {
    label: 'Ngày 4',
    value: 4,
  },
  {
    label: 'Ngày 5',
    value: 5,
  },
  {
    label: 'Ngày 6',
    value: 6,
  },
  {
    label: 'Ngày 7',
    value: 7,
  },
  {
    label: 'Ngày 8',
    value: 8,
  },
  {
    label: 'Ngày 9',
    value: 9,
  },
  {
    label: 'Ngày 10',
    value: 10,
  },
  {
    label: 'Ngày 11',
    value: 11,
  },
  {
    label: 'Ngày 12',
    value: 12,
  },
  {
    label: 'Ngày 13',
    value: 13,
  },
  {
    label: 'Ngày 14',
    value: 14,
  },
  {
    label: 'Ngày 15',
    value: 15,
  },
  {
    label: 'Ngày 16',
    value: 16,
  },
  {
    label: 'Ngày 17',
    value: 17,
  },
  {
    label: 'Ngày 18',
    value: 18,
  },
  {
    label: 'Ngày 19',
    value: 19,
  },
  {
    label: 'Ngày 20',
    value: 20,
  },
  {
    label: 'Ngày 21',
    value: 21,
  },
  {
    label: 'Ngày 22',
    value: 22,
  },
  {
    label: 'Ngày 23',
    value: 23,
  },
  {
    label: 'Ngày 24',
    value: 24,
  },
  {
    label: 'Ngày 25',
    value: 25,
  },
  {
    label: 'Ngày 26',
    value: 26,
  },
  {
    label: 'Ngày 27',
    value: 27,
  },
  {
    label: 'Ngày 28',
    value: 28,
  },
  {
    label: 'Ngày 29',
    value: 29,
  },
  {
    label: 'Ngày 30',
    value: 30,
  },
  {
    label: 'Ngày 31',
    value: 31,
  },
];

const INTERACTION_MAX_LENGTH = {
  MAX_LENGTH_TEMPLATE_NAME: 128,
  MAX_LENGTH_DPOINT_APP_TITLE: 50,
  MAX_LENGTH_DPOINT_APP_DESC_TABLE: 15,
  MAX_LENGTH_DPOINT_APP_1_CTA_NAME: 30,
  MAX_LENGTH_DPOINT_APP_2_CTA_NAME: 15,
};

const INTERACTION_REGEX = {
  PARAM_REGEX: /\[\[(.*?)\]\]/g,
  PATTERN: /\[{2}([A-z]|\])*$/,
};

const INTERACTION_VIEW_TYPE = {
  READ: 'READ',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
};

const INTERACTION_CONTENT_SOURCE = {
  TEMPLATE: 0,
  CUSTOM: 1,
};

const NUMBER_OF_CTA_BUTTON_OPTIONS = [
  {
    label: '1 nút',
    value: true,
  },
  {
    label: '2 nút',
    value: false,
  },
];

const APPLICABLE_MERCHANT_OPTIONS = [
  {
    label: 'Tất cả doanh nghiệp',
    value: true,
  },
  {
    label: 'Doanh nghiệp cụ thể',
    value: false,
  },
];

const AUTO_INTERACTION_TABS = {
  OBJECT_SETTING_TAB: 'OBJECT_SETTING_TAB',
  ACTIVITY_TAB: 'ACTIVITY_TAB',
  SENDING_TIME_TAB: 'SENDING_TIME_TAB',
  CHANNEL_CONTENT_TAB: 'CHANNEL_CONTENT_TAB',
};

const INTERACTION_SEND_CHANNEL_OPTIONS = [
  {
    label: 'Gửi tất cả các kênh',
    value: true,
  },
  {
    label: 'Gửi theo độ ưu tiên (Gửi kênh ưu tiên cao, thất bại sẽ gửi kênh ưu tiên thấp hơn)',
    value: false,
  },
];

const INTERACTION_CONTENT_SOURCE_OPTIONS = [
  {
    label: 'Theo mẫu',
    value: INTERACTION_CONTENT_SOURCE.TEMPLATE,
  },
  {
    label: 'Tuỳ chỉnh',
    value: INTERACTION_CONTENT_SOURCE.CUSTOM,
  },
];

const ENTER_DEEP_LINK_OPTION = {
  label: 'Nhập deep link màn hình chi tiết',
  value: 'ENTER_DEEP_LINK',
};

const CONTENT_TEMPLATE_MANAGEMENT_TAB_KEYS = {
  PARAM_MANAGEMENT: 'PARAM_MANAGEMENT',
  CONTENT_TEMPLATE: 'CONTENT_TEMPLATE',
};

const CONTENT_TEMPLATE_MANAGEMENT_TAB_ITEMS = [
  {
    title: 'Tham số',
    id: CONTENT_TEMPLATE_MANAGEMENT_TAB_KEYS.PARAM_MANAGEMENT,
  },
  {
    title: 'Mẫu nội dung',
    id: CONTENT_TEMPLATE_MANAGEMENT_TAB_KEYS.CONTENT_TEMPLATE,
  },
];

const ACCOUNTS_SEND_MESSAGES_TAB_KEYS = {
  SMS: 'SMS',
  ZALO_OA: 'ZNS',
};

const ACTIVE_STATUS_OPTIONS = [
  {label: 'Hoạt động', value: true},
  {label: 'Ngưng hoạt động', value: false},
];

export {
  API_ENDPOINTS_HOST,
  API_METHOD,
  STORAGE_KEY,
  DPOINT_PERMISSIONS,
  EVENTTIMEOPTION,
  ACTIONOPTIONS,
  STORE_TYPE,
  GAME_TYPE,
  DYNAMIC_TYPE,
  KEYCODES,
  DISPLAYSCREEN,
  STATUS,
  CLASSIFIEDGIFTLIST,
  NOTIFICATION_CLASSIFY,
  PROCESSING_STATUS,
  NOTIFICATION_TYPE_ENDPOINT,
  ATTACH_REFERENCES_TYPE_DATA,
  REDIRECT_PATHS,
  DEEPLINK_TABNAME_DATA,
  DEEPLINK_TABID_OPTIONS,
  DEEPLINK_DETAIL_TYPE_OPTIONS,
  DEEPLINK_GAMETYPE_OPTIONS,
  AUDIT_LOG_TYPE,
  NULL_OBJECT_ID,
  PERMISSION_TABNAME_DATA,
  MERCHANT_ACCOUNT_CONTACT_SOURCE,
  MERCHANT_ACCOUNT_ROLE_NAME,
  GIFT_TIME_OPTION_VALUE,
  GIFT_TIME_START_REDEEM_OPTIONS,
  GIFT_TIME_END_REDEEM_OPTIONS,
  GIFT_TIME_START_USAGE_OPTIONS,
  GIFT_TIME_START_USAGE_BY_SPECIFIC_EVENT_OPTIONS,
  GIFT_TIME_END_USAGE_OPTIONS,
  GIFT_TIME_END_USAGE_BY_DURATION_OPTIONS,
  GIFT_TIME_END_USAGE_BY_TIMELINE_OPTIONS,
  MAX_PROMOTION_PER_USER_PERIOD_TYPE,
  EARNING_RULE_PERFORMANCE_TYPE,
  MAX_EARNING_RULE_PER_USER_PERIOD_TYPE,
  POS_NAME_OPTIONS,
  PASSWORD_MANAGEMENT_TABNAME,
  PASSWORD_MANAGEMENT_TYPE_OPTIONS,
  CONTENT_ATTACH_REFERENCES_TYPE,
  PARTNER_STATUS_OPTIONS,
  NOTIFICATION_CHANNELS,
  NOTIFICATION_CHANNEL_OPTIONS,
  CONTENT_TEMPLATE_CHANNEL_OPTIONS,
  ATTACHMENT_TYPE_OPTIONS,
  CONTENT_TEMPLATE_POSITION_CTA,
  POSITION_CTA_OPTIONS,
  AUTOMATIC_INTERACTION_STATUS_OPTIONS,
  AUTOMATIC_INTERACTION_VALIDITY_PERIOD,
  AUTOMATIC_INTERACTION_VALIDITY_PERIOD_OPTIONS,
  AUTO_INTERACTION_MAX_PER_USER_PERIOD_TYPE,
  TIME_TYPE_1,
  AUTO_INTERACTION_ACTIVITY_PERIOD_TYPE,
  ACTIVITY_TYPE,
  AUTO_INTERACTION_SENDING_TIME_TYPE,
  AUTO_INTERACTION_SENDING_TIME_PERIOD_TYPE,
  DAY_OF_WEEK_OPTIONS,
  DAY_OF_MONTH_OPTIONS,
  INTERACTION_MAX_LENGTH,
  INTERACTION_REGEX,
  INTERACTION_VIEW_TYPE,
  INTERACTION_CONTENT_SOURCE,
  NUMBER_OF_CTA_BUTTON_OPTIONS,
  APPLICABLE_MERCHANT_OPTIONS,
  AUTO_INTERACTION_TABS,
  INTERACTION_SEND_CHANNEL_OPTIONS,
  INTERACTION_CONTENT_SOURCE_OPTIONS,
  ENTER_DEEP_LINK_OPTION,
  CONTENT_TEMPLATE_MANAGEMENT_TAB_KEYS,
  CONTENT_TEMPLATE_MANAGEMENT_TAB_ITEMS,
  ACCOUNTS_SEND_MESSAGES_TAB_KEYS,
  ACTIVE_STATUS_OPTIONS,
};
