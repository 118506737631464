import axios from 'axios';
import authService from 'src/authorization/AuthorizeService';
import {API_ENDPOINTS_HOST, STORAGE_KEY} from 'src/constants';
import * as actions from '../redux/actions';
import {ApplicationPaths} from 'src/authorization/ApiAuthorizationConstants';

export function getApiBaseUrl(serviceEndpoint) {
  let service = '';

  switch (serviceEndpoint) {
    case 'REACT_APP_IDENTITY_ADDRESS':
      return process.env.REACT_APP_IDENTITY_ADDRESS;

    case 'REACT_APP_API_TRANSACTION':
      service = 'transaction';
      break;

    case 'REACT_APP_API_ADS':
      service = 'ads';
      break;

    case 'REACT_APP_API_GAME':
      service = 'game';
      break;

    case 'REACT_APP_API_CDP':
      service = 'cdp';
      break;

    case 'REACT_APP_API_CMS':
      service = 'cms';
      break;

    case 'REACT_APP_API_AUTH':
      service = 'auth/enduser';
      break;

    case 'REACT_APP_API_MASTER_DATA':
      service = 'masterdata';
      break;
    case 'REACT_API_API_CRM':
      service = 'crm';
      break;

    case 'REACT_APP_API_GIFT':
      service = 'gift';
      break;
    case API_ENDPOINTS_HOST.REACT_APP_API_ACTIVITY:
      service = 'activity';
      break;
    case API_ENDPOINTS_HOST.REACT_APP_API_REPORT:
      service = 'report';
      break;
    default:
  }

  return `${process.env.REACT_APP_API_URL}/${service}`;
}

export async function setupAxios(axios, store) {
  //const token = await authService.getAccessToken();
  const token =
    localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN) || (await authService.getAccessToken());
  axios.interceptors.request.use(
    (config) => {
      config.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      };

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (error) => {
      const {dispatch} = store;

      if (error?.response?.status === 401) {
        dispatch(actions.controlLoading(false));

        localStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
        localStorage.removeItem(STORAGE_KEY.MERCHANT_ID);

        window.location.href = ApplicationPaths.Login;
      }

      return Promise.reject(error);
    }
  );
}

export default function requestApi(
  serviceName,
  endpoint,
  method,
  body,
  responseType = 'json',
  cancelToken = null,
  options = {}
) {
  const baseUrl = getApiBaseUrl(serviceName);

  if (cancelToken !== null) {
    return axios.request({
      method: method,
      url: `${baseUrl}${endpoint}`,
      data: body,
      responseType: responseType,
      cancelToken: cancelToken,
      ...options,
    });
  } else {
    return axios.request({
      method: method,
      url: `${baseUrl}${endpoint}`,
      data: body,
      responseType: responseType,
      ...options,
    });
  }
}
