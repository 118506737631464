import moment from 'moment';
import dateFormat from 'dateformat';
import authService from 'src/authorization/AuthorizeService';
import {toast} from 'react-toastify';
import {getApiBaseUrl} from './apiHelper';

export function phoneRegExp() {
  return /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
}

export function matchStateToTerm(state, value) {
  return (
    state.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
    state.code.toLowerCase().indexOf(value.toLowerCase()) !== -1
  );
}

/**
 * An example of how to implement a relevancy-based sorting method. States are
 * sorted based on the location of the match - For example, a search for "or"
 * will return "Oregon" before "North Carolina" even though "North Carolina"
 * would normally sort above Oregon. Strings where the match is in the same
 * location (or there is no match) will be sorted alphabetically - For example,
 * a search for "or" would return "North Carolina" above "North Dakota".
 */
export function sortStates(a, b, value) {
  const aLower = a.name.toLowerCase();
  const bLower = b.name.toLowerCase();
  const valueLower = value.toLowerCase();
  const queryPosA = aLower.indexOf(valueLower);
  const queryPosB = bLower.indexOf(valueLower);
  if (queryPosA !== queryPosB) {
    return queryPosA - queryPosB;
  }
  return aLower < bLower ? -1 : 1;
}

export function getStatusNameByCode(code) {
  let statusName = '';
  switch (code) {
    case 0:
      statusName = 'Từ chối';
      break;
    case 1:
      statusName = 'Chấp thuận';
      break;
    case 2:
      statusName = 'Chờ duyệt';
      break;
    default:
      statusName = 'Điều chỉnh';
      break;
  }
  return statusName;
}

export function getStatusNameGift(code) {
  let statusName = '';
  switch (code) {
    case 1:
      statusName = 'Bản nháp';
      break;
    case 2:
      statusName = 'Chờ duyệt';
      break;
    case 3:
      statusName = 'Đã duyệt';
      break;
    case 4:
      statusName = 'Hủy chờ duyệt';
      break;
    case 5:
      statusName = 'Từ chối';
      break;
    case 6:
      statusName = 'Chờ gỡ khỏi ứng dụng';
      break;
    case 7:
      statusName = 'Đã gỡ khỏi ứng dụng';
      break;
    case 8:
      statusName = 'Đang duyệt';
      break;
    default:
      statusName = 'Đã xóa';
      break;
  }
  return statusName;
}

export function getStatusNameExchangeRate(code) {
  let statusName = '';
  switch (code) {
    case 1:
      statusName = 'Bản nháp';
      break;
    case 2:
      statusName = 'Chờ duyệt';
      break;
    case 3:
      statusName = 'Đã duyệt';
      break;
    case 4:
      statusName = 'Hủy chờ duyệt';
      break;
    case 5:
      statusName = 'Từ chối';
      break;
    case 6:
      statusName = 'Hết hạn';
      break;
    default:
      statusName = 'Đã xóa';
      break;
  }
  return statusName;
}

export function getStatusNameObjectSetting(code) {
  let statusName = '';
  switch (code) {
    case 0:
      statusName = 'Bản nháp';
      break;
    case 3:
      statusName = 'Hoàn thành';
      break;
    case 2:
      statusName = 'Đang sử dụng';
      break;
    case 1:
      statusName = 'Ngưng sử dụng';
      break;
    default:
      statusName = 'Đã xóa';
      break;
  }
  return statusName;
}

export function getStatusNameContent(code) {
  let statusName = '';
  switch (code) {
    case 0:
      statusName = 'Bản nháp';
      break;
    case 1:
      statusName = 'Hoàn thành';
      break;
    case 2:
      statusName = 'Chờ duyệt';
      break;
    case 3:
      statusName = 'Hủy gửi duyệt';
      break;
    case 4:
      statusName = 'Đã duyệt';
      break;
    case 5:
      statusName = 'Đã từ chối';
      break;
    case 6:
      statusName = 'Chờ gỡ khỏi ứng dụng';
      break;
    default:
      statusName = 'Đã gỡ khỏi ứng dụng';
      break;
  }

  return statusName;
}

export function getStatusNameGameSeting(code) {
  let statusName = '';
  switch (code) {
    case 0:
      statusName = 'Bản nháp';
      break;
    case 1:
      statusName = 'Chờ duyệt';
      break;
    case 2:
      statusName = 'Hủy gửi duyệt';
      break;
    case 3:
      statusName = 'Đã duyệt';
      break;
    case 4:
      statusName = 'Bị từ chối';
      break;
    case 5:
      statusName = 'Chờ gỡ khỏi ứng dụng';
      break;
    case 6:
      statusName = 'Đã gỡ khỏi ứng dụng';
      break;
    default:
      statusName = 'Đã xóa';
      break;
  }
  return statusName;
}

export function getStatusMissionName(code) {
  let statusName = '';
  switch (code) {
    case 1:
      statusName = 'Bản nháp';
      break;
    case 2:
      statusName = 'Hoàn thành';
      break;
    case 3:
      statusName = 'Đang sử dụng';
      break;
    default:
      statusName = 'Ngưng sử dụng';
      break;
  }
  return statusName;
}

export function getMissionTypeName(code) {
  let statusName = '';
  switch (code) {
    case 1:
      statusName = 'Khảo sát';
      break;
    default:
      statusName = 'Trả lời câu hỏi';
      break;
  }
  return statusName;
}

/**
 * This function is equivalent to PHP's array_column
 * @param array
 * @param column
 */
export const array_column = (array, column) => array.map((e) => e[column]);

/**
 * Convert default base64String to valid string
 * @param base64String
 */
export function validBase64String(base64String) {
  return base64String
    .replace('data:image/jpeg;base64,', '')
    .replace('data:image/png;base64,', '')
    .replace('data:application/pdf;base64,', '');
}

export function getMissionTypes(typeName) {
  let code = 1;
  switch (typeName) {
    case 'Khảo sát':
      code = 1;
      break;
    case 'Trả lời câu hỏi':
      code = 2;
      break;
    default:
      code = 1;
      break;
  }
  return code;
}

export function getQuestionTypes(code) {
  let typeName = 'Chọn một';
  switch (code) {
    case 1:
      typeName = 'Chọn một';
      break;
    case 2:
      typeName = 'Chọn nhiều';
      break;
    case 3:
      typeName = 'Đoạn văn';
      break;
    default:
      typeName = 'Chọn một';
      break;
  }
  return typeName;
}

export function getRewardType(typeName) {
  let code = 1;
  switch (typeName) {
    case 'pointReward':
      code = 1;
      break;
    case 'physicalGift':
      code = 2;
      break;
    default:
      code = 1;
      break;
  }
  return code;
}

export function getStatusNameContentSettings(code) {
  let statusName = '';
  switch (code) {
    case -1:
      statusName = 'Đã xóa';
      break;
    case 0:
      statusName = 'Bản nháp';
      break;
    case 1:
      statusName = 'Chờ duyệt';
      break;
    case 2:
      statusName = 'Hủy gửi duyệt';
      break;
    case 3:
      statusName = 'Đã duyệt';
      break;
    case 4:
      statusName = 'Bị từ chối';
      break;
    case 5:
      statusName = 'Chờ gỡ khỏi ứng dụng';
      break;
    default:
      statusName = 'Đã gỡ khỏi ứng dụng';
      break;
  }
  return statusName;
}

export function getProvinceLocationMerchant(code) {
  let province = '';
  switch (code) {
    case 'Hà Nội':
      province = 'thủ đô';
      break;
    default:
      province = 'TP';
      break;
  }
  return province;
}

export function convertUTCtoDate(utcTime) {
  const date = new Date(0);
  date.setUTCSeconds(utcTime);
  return date;
}

export function getStatusNameGameRequest(code) {
  let statusName = '';
  switch (code) {
    case 99:
      statusName = 'Đã xóa';
      break;
    case 0:
      statusName = 'Bản nháp';
      break;
    case 1:
      statusName = 'Chờ duyệt';
      break;
    case 2:
      statusName = 'Hủy gửi duyệt';
      break;
    case 3:
      statusName = 'Đã duyệt';
      break;
    case 4:
      statusName = 'Bị từ chối';
      break;
    case 5:
      statusName = 'Chờ gỡ khỏi ứng dụng';
      break;
    default:
      statusName = 'Đã gỡ khỏi ứng dụng';
      break;
  }
  return statusName;
}

export function getStatusNameGameCategory(code) {
  let statusName = '';
  switch (code) {
    case 0:
      statusName = 'Ngưng hoạt động';
      break;
    case 1:
      statusName = 'Hoạt động';
      break;
    default:
      statusName = 'Ngưng hoạt động';
      break;
  }
  return statusName;
}

export function getStatusNameGiftCategory(code) {
  let statusName = '';
  switch (code) {
    case 0:
      statusName = 'Ngưng hoạt động';
      break;
    case 1:
      statusName = 'Hoạt động';
      break;
    default:
      statusName = 'Ngưng hoạt động';
      break;
  }
  return statusName;
}

export function convertToPlain(html) {
  // Create a new div element
  var tempDivElement = document.createElement('div');

  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;

  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || '';
}

export const customStyleDataTable = {
  headCells: {
    style: {
      fontSize: '14px',
    },
  },
  rows: {
    style: {
      minHeight: 'minContent',
    },
  },
  cells: {
    style: {
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
  },
};

export const ckeditorUploadConfig = {
  filebrowserImageUploadUrl: `${getApiBaseUrl('REACT_APP_API_MASTER_DATA')}/api/ckeditor/upload`,
  fileTools_requestHeaders: {
    Authorization: `Bearer ${authService.getAccessTokenFromStorage()}`,
  },
  extraPlugins: 'justify,font,colorbutton,editorplaceholder',
  versionCheck: false,
};

export function getStatusNameTransactionPoints(code) {
  let statusName = '';
  switch (code) {
    case 0:
      statusName = 'Chờ xác nhận';
      break;
    case 1:
      statusName = 'Hoàn tất';
      break;
    default:
      statusName = 'Đã hủy';
      break;
  }
  return statusName;
}

export function getStatusNameTransactionGifts(code) {
  let statusName = '';
  switch (code) {
    case 1:
      statusName = 'Đã đổi';
      break;
    case 2:
      statusName = 'Đã sử dụng';
      break;
    default:
      statusName = 'Đã hết hạn';
      break;
  }
  return statusName;
}

export function getStatusNameLoyalty(code) {
  let statusName = '';
  switch (code) {
    case -1:
      statusName = 'Đã xóa';
      break;
    case 1:
      statusName = 'Đã lưu';
      break;
    case 2:
      statusName = 'Chờ duyệt';
      break;
    case 3:
      statusName = 'Đã duyệt';
      break;
    case 4:
      statusName = 'Hủy gửi duyệt';
      break;
    case 5:
      statusName = 'Đã từ chối';
      break;
    case 6:
      statusName = 'Chờ gỡ khỏi ứng dụng';
      break;
    default:
      statusName = 'Đã gỡ khỏi ứng dụng';
      break;
  }
  return statusName;
}

export function getStatusNameEarning(code) {
  let statusName = '';
  switch (code) {
    case 1:
      statusName = 'Bản nháp';
      break;
    case 2:
      statusName = 'Hoàn thành';
      break;
    case 3:
      statusName = 'Đã duyệt';
      break;
    default:
      break;
  }
  return statusName;
}

export const checkExistFields = (dataSavedField, fieldsState, targetName, targetValue) => {
  if (dataSavedField) {
    if (dataSavedField !== targetValue) {
      if (!fieldsState.includes(targetName)) {
        fieldsState.push(targetName);
      }
    } else {
      const index = fieldsState.findIndex((f) => f === targetName);
      fieldsState.splice(index, 1);
    }
  }
};

export const checkExistFieldsCKEditor = (dataSavedField, fieldsState, targetName, targetValue) => {
  if (dataSavedField) {
    if (dataSavedField + '\n' !== targetValue) {
      if (!fieldsState.includes(targetName)) {
        fieldsState.push(targetName);
      }
    } else {
      const index = fieldsState.findIndex((f) => f === targetName);
      fieldsState.splice(index, 1);
    }
  }
};

export const checkExistFieldsInArray = (dataSavedField, fieldsState, targetName, targetValue) => {
  if (dataSavedField && dataSavedField.length !== 0) {
    if (JSON.stringify(dataSavedField.sort()) !== JSON.stringify(targetValue.sort())) {
      if (!fieldsState.includes(targetName)) {
        fieldsState.push(targetName);
      }
    } else {
      const index = fieldsState.findIndex((f) => f === targetName);
      fieldsState.splice(index, 1);
    }
  }
};

export const getIds = (array) => {
  let filterIds = [];
  if (array.length !== 0) {
    array.forEach(function (item, index) {
      filterIds.push(item.value);
    });
  }
  return filterIds;
};

export const getIdByTableItems = (array) => {
  let filterIds = [];
  if (array.length !== 0) {
    array.forEach(function (item, index) {
      filterIds.push(item.id);
    });
  }
  return filterIds;
};

export const renderErrorMessage = (errors, elm) => {
  return errors[elm] && <p style={{color: 'red', marginTop: '-10px'}}>{errors[elm].message}</p>;
};

export const defaultChecked = (itemId, itemSelected) => {
  return itemSelected &&
    itemSelected.length !== 0 &&
    itemSelected.find((item) => item.id === itemId)
    ? true
    : false;
};

export const isCheckedAll = (columnData, itemSelected) => {
  return columnData &&
    columnData.length !== 0 &&
    columnData.every((data) => {
      const existedPlace = itemSelected.find((place) => place.id === data.id);
      return existedPlace ? true : false;
    })
    ? true
    : false;
};

export const getFullTimeForPayload = (time) => {
  let timeParam = moment(time).format('HH:mm:ss');
  let dateParam = dateFormat(time, 'yyyy-mm-dd');
  return dateParam + ' ' + timeParam;
};

export const getEndTimeWithoutTime = (time) => {
  time.setHours(0);
  time.setMinutes(0);
  time.setSeconds(0);
  time.setHours(time.getHours() + 23);
  time.setMinutes(time.getMinutes() + 59);
  time.setSeconds(time.getSeconds() + 59);
  return time;
};

export const getStartTimeWithoutTime = (time) => {
  time.setHours(0);
  time.setMinutes(0);
  time.setSeconds(0);
  return time;
};

export const getMinPerformanceType = (type) => {
  let name = '';
  switch (type) {
    case 'ACCUMULATED_PURCHASE_AMOUNT':
      name = 'Doanh số tối thiểu';
      break;
    case 'ACCUMULATED_REWARDS':
      name = 'Điểm tích lũy tối thiểu';
      break;
    case 'BILL_AMOUNT':
      name = 'Giá trị tối thiểu';
      break;
    case 'ACCUMULATED_PURCHASE_NUMBER':
      name = 'Lần mua hàng';
      break;
    default:
      name = 'Số lượng tối thiểu';
      break;
  }
  return name;
};

export const getMaxPerformanceType = (type) => {
  let name = '';
  switch (type) {
    case 'ACCUMULATED_PURCHASE_AMOUNT':
      name = 'Doanh số tối đa';
      break;
    case 'ACCUMULATED_REWARDS':
      name = 'Điểm tích lũy tối đa';
      break;
    case 'BILL_AMOUNT':
      name = 'Giá trị tối đa';
      break;
    case 'NUMBER_REFERRAL_LOYALTY':
      name = 'Số bạn tối đa mời đăng ký chương trình khách hàng thân thiết';
      break;
    case 'NUMBER_REFERRAL':
      name = 'Số bạn tối đa mời tải Dpoint';
      break;
    default:
      name = 'Số lượng tối đa';
      break;
  }
  return name;
};

export const getDiscountTypeLabelByVal = (val) => {
  let name = '';
  switch (val) {
    case 'Fixed':
      name = 'Giá trị cố định';
      break;
    case 'Percentage':
      name = 'Giảm %';
      break;
    default:
      name = '-';
      break;
  }
  return name;
};

export const getExchangeFeeStatus = (val) => {
  let name = '';
  switch (val) {
    case 1:
      name = 'Bản nháp';
      break;
    case 2:
      name = 'Chờ duyệt';
      break;
    case 3:
      name = 'Đã duyệt';
      break;
    case 4:
      name = 'Hủy gửi duyệt';
      break;
    case 5:
      name = 'Đã từ chối';
      break;
    case 6:
      name = 'Hết hạn';
      break;
    default:
      name = 'Đã xóa';
      break;
  }
  return name;
};
export const getRewardStatus = (val) => {
  let name = '';
  switch (val) {
    case 0:
      name = 'Bản nháp';
      break;
    case 1:
      name = 'Chờ duyệt';
      break;
    case 3:
      name = 'Đã duyệt';
      break;
    case 2:
      name = 'Hủy gửi duyệt';
      break;
    case 4:
      name = 'Đã từ chối';
      break;
    default:
      name = 'Đã xóa';
      break;
  }
  return name;
};

export function downloadFile(data, filename, mime) {
  const blob = new Blob([data], {type: mime || 'application/octet-stream'});
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, filename);
    return;
  }
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', filename);
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    window.URL.revokeObjectURL(blobURL);
  }, 100);
}

export function handleLazyLoadError(err) {
  console.error(err);
  window.location.reload();
}

export function handleApiError(err, callback = () => {}) {
  if (typeof err.response !== 'undefined') {
    if (err.response.status === 400) {
      callback();
      let messages = err.response.data.messages;
      messages.forEach(function (e, index) {
        toast.error(e.content, {autoClose: 2000, position: 'top-center'});
      });
    }
  }
}

export function getBannedKeywords(bannedKeywords, data, type) {
  if (data !== undefined) {
    if (type === 'string') {
      return bannedKeywords.filter((val) =>
        val.indexOf(' ') === -1
          ? data
              .split(' ')
              .map((x) => x.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''))
              .includes(val.toLowerCase())
          : data.toLowerCase().indexOf(val.toLowerCase()) > -1
      );
    } else {
      return bannedKeywords.filter((val) =>
        val.indexOf(' ') === -1
          ? data
              .map((x) => x.text.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''))
              .includes(val.toLowerCase())
          : data
              .map((x) => x.text)
              .join(', ')
              .toLowerCase()
              .indexOf(val.toLowerCase()) > -1
      );
    }
  } else {
    return [];
  }
}

export function getDeeplinksStatusName(code) {
  let statusName = '';
  switch (code) {
    case 99:
      statusName = 'Ngưng hoạt động';
      break;
    case 1:
      statusName = 'Hoạt động';
      break;
    default:
      statusName = 'Ngưng hoạt động';
      break;
  }
  return statusName;
}

export function handleErrorFromApi(err, callback = () => {}) {
  if (typeof err.response !== 'undefined') {
    callback();
    err.response.data.messages?.forEach(function (e) {
      toast.error(e.content, {autoClose: 2000, position: 'top-center'});
    });
  }
  console.error(err);
}

export function handleSuccessFromApi(response, callback = () => {}) {
  callback();
  response.data.messages?.forEach(function (e) {
    toast.success(e.content, {autoClose: 2000, position: 'top-center'});
  });
}

export function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );
  return !!pattern.test(str);
}

export function checkIsIntegerNumber(number) {
  return Number(number) === number && number % 1 === 0;
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function containsSpecialChars(str) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
}

export function getParamsNameFromMatch(str) {
  return str?.slice(2, str?.length - 2);
}

export function checkListParams(listData = [], paramByAct = []) {
  let listParamNameErr = [];
  let isCheckError = false;
  listData.forEach((item) => {
    if (item.paramId) {
      if (!paramByAct.some((pa) => pa.id === item.paramId)) {
        isCheckError = true;
        listParamNameErr.push(item.paramName);
      }
    } else {
      isCheckError = true;
      listParamNameErr.push(item.paramName);
    }
  });
  return {isCheckError, listParamNameErr};
}

export function getPositionDeepLinkOpt(listOptions = [], matchValue) {
  let optionReturn;
  listOptions.forEach((item) => {
    let obj = item?.options?.find((opt) => opt.value === matchValue);
    if (obj) optionReturn = obj;
  });

  if (optionReturn) return optionReturn;
  else return null;
}

export function convertListParamsToSubmit(listData = []) {
  return listData?.map((item) => ({
    index: item.index,
    paramId: item.paramId,
    paramName: item?.paramName,
  }));
}

export function convertStringWithParam(str = '', listParams = [], type = 'SUBMIT') {
  let newStr = str;
  if (type === 'SUBMIT') {
    listParams.forEach((item) => {
      newStr = newStr.replace(`[[${item.paramName}]]`, `{${item.index}}`);
    });
  } else if (type === 'INIT') {
    listParams.forEach((item) => {
      newStr = newStr.replace(`{${item.index}}`, `[[${item.paramName}]]`);
    });
  }
  return newStr;
}

export function convertStringToTimePickerData(str = '', format = 'HH:mm') {
  if (!moment(str, format)?.isValid()) return null;
  return moment(str, format).toDate();
}

export const convertConfigFieldsToInit = (accountSetting) => {
  if (!accountSetting) return {};
  let updatedAccountSetting = {...accountSetting};
  let data = updatedAccountSetting?.data;
  for (const property in data) {
    updatedAccountSetting[property] = data[property];
  }
  return updatedAccountSetting;
};
