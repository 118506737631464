import {useState, useEffect} from 'react';
import authService from './AuthorizeService';
import jwt from 'jwt-decode';

export const useAuthorize = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const user = authService.getAccessTokenFromStorage()
      ? jwt(authService.getAccessTokenFromStorage())
      : {};
    if (user?.permission?.length > 0) {
      setUser(user);
    } else {
      authService.subscribe(() => authenticationChanged());
      populateAuthenticationState();
    }
  }, []);

  const authenticationChanged = async () => {
    setUser(null);
    await populateAuthenticationState();
  };

  const populateAuthenticationState = async () => {
    const user = await authService.getUser();
    setUser(user);
  };

  const isAuthorize = ({permissions = [], orPermissions = []}) => {
    if (!user) return false;

    if (!user.permission || !Array.isArray(user.permission) || user.permission.length === 0)
      return false;

    if (permissions && permissions.length > 0) {
      return permissions.every((permission) => user.permission.includes(permission));
    }

    if (orPermissions && orPermissions.length > 0) {
      return orPermissions.some((permission) => user.permission.includes(permission));
    }

    return false;
  };
  return {isAuthorize};
};

export default function AuthorizeFragment({
  permissions = [],
  orPermissions = [],
  children,
  ...props
}) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const user = authService.getAccessTokenFromStorage()
      ? jwt(authService.getAccessTokenFromStorage())
      : {};
    if (user?.permission?.length > 0) {
      setUser(user);
    } else {
      authService.subscribe(() => authenticationChanged());
      populateAuthenticationState();
    }
  }, []);

  const authenticationChanged = async () => {
    setUser(null);
    await populateAuthenticationState();
  };

  const populateAuthenticationState = async () => {
    const user = await authService.getUser();
    setUser(user);
  };

  const isAuthorize = (user) => {
    if (!user) return false;

    if (!user.permission || !Array.isArray(user.permission) || user.permission.length === 0)
      return false;

    if (permissions && permissions.length > 0) {
      return permissions.every((permission) => user.permission.includes(permission));
    }

    if (orPermissions && orPermissions.length > 0) {
      return orPermissions.some((permission) => user.permission.includes(permission));
    }

    return false;
  };

  return isAuthorize(user) && children;
}
